import { useSelector } from "react-redux";
import { RootState } from "../slices";
import { BillingAddress } from "src/interfaces/customer";
import { Address } from "@generated/types";

export const loginSelector = (state: RootState) => state.login;
export const isAuthenticatedSelector = (state: RootState) =>
  !!state.login.data?.customer?.id;
export const isJustLoggedInSelector = (state: RootState): boolean => {
  return state.login.justLoggedIn;
};
export const isAuthenticatingSelector = (state: RootState) =>
  !!state.login.loading;

export const authRememberMeSelector = (state: RootState) =>
  state.authRememberMe;

export const useLoginSelector = () => useSelector(loginSelector);

export const useForgotPasswordSelector = () =>
  useSelector((state: RootState) => state.forgotPassword);

export const useResetPasswordSelector = () =>
  useSelector((state: RootState) => state.resetPassword);

export const registerSelector = (state: RootState) => state.register;
export const useRegisterSelector = () => useSelector(registerSelector);
export const useIsAuthenticated = () => useSelector(isAuthenticatedSelector);
export const useIsAuthenticating = () => useSelector(isAuthenticatingSelector);

export const useIsXFhAuthValid = () => useSelector(authRememberMeSelector);
export const useIsJustLoggedIn = () => useSelector(isJustLoggedInSelector);
